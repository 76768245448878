<template>
  <div id="home">
  PROCESS
  </div>
</template>

<script>
  export default {
    metaInfo: {
      meta: [
        { name: 'description', content: 'Oria Agriculture' },
      ],
    },
    components: {

      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },
    data: () => ({
      promotions: [],
      promotions_load: false,
      num_promotions: 0,
      windowWidth: window.innerWidth,
    }),
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth
        })
      })
    },
    beforeCreate () {
      const promotionsRef = this.$firebaseDatabase.collection('dick_sales')
      console.log('test beforeCreate')
      promotionsRef.limit(1).get().then(function (querySnapshot) {
        console.log('len ' + querySnapshot.docs.length)
        querySnapshot.docs.forEach(snapshot => {
          if (snapshot.data().done !== true) {
            const _data = snapshot.data()

            console.log('get product from ref ' + _data.ref)
            fetch('https://erp.oriaagriculture.com/api/index.php/products/ref/' + _data.ref, { headers: { DOLAPIKEY: '64qrw3ck', Accept: 'application/json' } })
              .then(r => r.json())
              .then(json => {
                      console.log('got json')
                      console.log(json)
                    },
                    response => {
                      console.log('Error loading json:', response)
                    })
          }
        })
      })
    },

    methods: {

      isMobile () {
        if (this.windowWidth <= 960) {
          return true
        } else {
          return false
        }
      },
    },

  }

</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
#projects
  .v-slide-group__content
    justify-content: center
</style>
